<template>
  <v-dialog v-model="dialog" max-width="574" :persistent="!isEdit">
    <div class="feedback-modal">
      <div class="feedback-modal__header">
        <span class="feedback-modal__header--title">
          Form {{ isEdit ? "Updated!" : "Created!" }}
        </span>
        <v-btn v-if="isEdit" @click="dialog = false" icon color="#596A73"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <div class="feedback-modal__body">
        <div class="feedback-modal__body--message">
          Your form <b>{{ formName }}</b> has been
          {{ isEdit ? "updated" : "created" }} successfully, what will you like
          to do next?
        </div>
        <div class="feedback-modal__body--content">
          <div class="embed-link">
            <span class="embed-link__label"
              >You can copy/share the link to the form</span
            >
            <div class="embed-link__container">
              <span>{{ formUrl }}</span>
              <v-btn @click="copy" icon>
                <img :src="require('@/assets/pbot_icons/copy_icon.svg')" />
              </v-btn>
            </div>
          </div>

          <span class="or">OR...</span>

          <div class="action-wrapper">
            <div class="action-wrapper--card" @click="connectWorkflow">
              <v-icon class="icon-size"> mdi-chevron-right </v-icon>
              <span>Connect a <b>Workflow</b></span>
            </div>
            <div class="action-wrapper--card" @click="previewForm">
              <v-icon class="icon-size"> mdi-chevron-right </v-icon>
              <span>
                Preview form in a
                <b>New Tab</b>
              </span>
            </div>

            <div class="action-wrapper--card edit" @click="continueEdit">
              <v-icon class="icon1-size"> mdi-check </v-icon>
              <span>Continue editing this <b>Form</b></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "feedbackModal",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      formData: null,
      formUrl: "",
      formBaseUrl:
        process.env.NODE_ENV === "production"
          ? "https://hypn.so/"
          : "https://dev.hypn.so/",
    };
  },

  computed: {
    formName() {
      return (this.formData?.form_title || "").replaceAll("_", " ");
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    openModal(data) {
      this.formData = data;
      this.dialog = true;

      this.formUrl = this.formBaseUrl + this.formData?.hypn_id;
    },

    connectWorkflow() {
      this.$router.replace(`/workflow/?form=${this.formData?.hypn_id}`);
      this.dialog = false;
    },
    //preview form in a new tab
    previewForm() {
      let formid = this.formData?.hypn_id;
      this.dialog = false;
      window.open(this.formBaseUrl + formid, "_blank");
      this.$router.replace(`/form/${this.formData?.id}`);
    },

    //continue editing this form
    continueEdit() {
      this.$router.replace(`/edit-form/${this.formData?.id}`);
      this.dialog = false;
    },

    copy() {
      const value = this.formUrl;

      navigator.clipboard.writeText(value);
      this.showToast({
        sclass: "success",
        show: true,
        message: `copied ${value} to clipboard`,
        timeout: 3000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-modal {
  border-radius: 8px;
  background: var(--new-bg-contrast, #f6f3ee);

  &__header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;

    @media screen and (min-width: 600px) {
      padding: 20px 50px;
    }

    &--title {
      color: var(--hyphen-Blue, #19283d);
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__body {
    &--message {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      padding: 30px 50px;
      border-bottom: 1px solid #d9dee1;
    }

    &--content {
      padding: 10px 40px;
      padding-bottom: 30px;

      @media screen and (min-width: 600px) {
        padding: 30px 50px;
        padding-bottom: 50px;
      }

      .embed-link {
        &__label {
          color: var(--body-text, rgba(25, 40, 61, 0.8));
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 183.333% */
          letter-spacing: 0.45px;
          display: block;
          margin-bottom: 10px;
        }

        &__container {
          border-radius: 8px;
          border: 1px solid #f6f3ee;
          background: #fff;
          padding: 11px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          span {
            color: var(--bodyTxt_old, #596a73);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 200% */
          }
        }
      }

      .or {
        display: block;
        font-weight: bold;
        margin: 10px 0;
        color: var(--body-text, rgba(25, 40, 61, 0.8));
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 183.333% */
        letter-spacing: 0.45px;
      }

      .action-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &--card {
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 23px 30px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
          cursor: pointer;

          b {
            color: var(--hyphen-Blue, #19283d);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
          }

          .icon1-size {
            font-size: 30px;
          }

          .icon-size {
            font-size: 35px;
            font-weight: 300;
          }

          &:hover {
            background: #f6f3ee;
            outline: 1px solid var(--brown, #d7a47b);

            i {
              color: var(--brown, #d7a47b);
            }
          }

          //   &.edit {
          //     outline: 1px solid var(--brown, #d7a47b);
          //     background: #f6f3ee;

          //     i {
          //       color: var(--brown, #d7a47b);
          //     }
          //   }
        }
      }
    }
  }
}
</style>

<template>
  <div class="edit-form">
    <div class="edit-form__header">
      <span
        class="titlex"
        :style="{
          fontSize: `${$vuetify.breakpoint.mdAndDown ? '20px' : '32px'}`,
        }"
        >Edit Form</span
      >

      <div class="edit-form__header--actions">
        <v-btn
          v-if="canPreview && !isSubmitting"
          dark
          elevation="0"
          class="text-capitalize"
          style="height: 54px; background: #d9dee1; border-radius: 4px"
          @click="showPreview = !showPreview"
          :style="{
            width: `${$vuetify.breakpoint.mdAndDown ? '130px' : '215px'}`,
          }"
        >
          <img :src="require('@/assets/pbot_icons/view.svg')" />
          <span
            style="
              padding-left: 8px;
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.636364px;
              color: var(--v-primary-base);
            "
            :style="{
              fontSize: `${$vuetify.breakpoint.mdAndDown ? '12px' : '14px'}`,
            }"
          >
            {{ !showPreview ? "Preview" : "Edit" }}
          </span>
        </v-btn>

        <v-btn
          :loading="isSubmitting"
          @click="submitForm"
          dark
          class="text-capitalize"
          style="
            height: 54px;
            background: var(--v-primary-base);
            box-shadow: ;
            border-radius: 4px;
          "
          :style="{
            width: `${$vuetify.breakpoint.mdAndDown ? '130px' : '215px'}`,
            opacity: `${!canPreview ? '0.8' : '1'}`,
            boxShadow: `${
              canPreview ? '0px 12px 22px rgba(0, 0, 0, 0.24)' : 'none'
            }`,
          }"
        >
          <img :src="require('@/assets/pbot_icons/add-form.svg')" />
          <span
            style="
              padding-left: 8px;
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.636364px;
              color: #ffffff;
            "
            :style="{
              fontSize: `${$vuetify.breakpoint.mdAndDown ? '12px' : '14px'}`,
            }"
          >
            Save
          </span>
        </v-btn>
      </div>
    </div>
    <div v-if="formDetails" class="edit-form__canvas-area">
      <div class="form-actions">
        <v-switch
          color="#19283D"
          hide-details="auto"
          class="m-0 p-0"
          v-model="formDetails.is_private"
          label="This form is Private"
        ></v-switch>

        <button @click="openFormSettings">
          <v-icon>mdi-cog-outline</v-icon> form settings
        </button>
      </div>
      <div class="form-canvas">
        <hyphen-form-builder
          id="form-builder"
          :details.prop="{
            name: formDetails.form_title,
            description: formDetails.form_description,
          }"
          @schema="getSchema"
          @canPreview="setPreviewState"
          :pre-schema.prop="currentFormSchema"
          :show-preview="showPreview"
          :form-id="formDetails.hypn_id"
          :hyphen-token="token"
          :hyphen-base-url="getBaseUrl()"
        />
      </div>
    </div>
    <feed-back-modal ref="feedBackModal" isEdit />
    <create-form-modal
      :formValue="fullForm"
      @updated="fetchFormsById"
      isEdit
      ref="formsettings"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FeedBackModal from "../../includes/overlays/createForm/feedBackModal.vue";
import CreateFormModal from "../../includes/overlays/createForm/createFormModal.vue";
export default {
  components: {
    FeedBackModal,
    CreateFormModal,
  },
  data() {
    return {
      isFetching: false,
      canPreview: false,
      showPreview: true,
      formBuilder: null,
      addUploadInput: null,
      field_names: [],
      isSubmitting: false,
      form_hypn_id: "",
      formBaseUrl:
        process.env.NODE_ENV === "production"
          ? "https://hypn.so/"
          : "https://dev.hypn.so/",
      form_id: "",
      configuration: {
        formTitle: "",
        isPrivate: false,
        formId: "",
      },
      bill: [],
      formDetails: null,
      formData: null,
      currentFormSchema: {},
      fullForm: null,
    };
  },
  mounted() {
    this.fetchFormsById();
    // this.formDetails = JSON.parse(sessionStorage.getItem("formDetails"));

    // if (!this.formDetails) {
    //   this.$router.push({ name: "forms" });
    // }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      organId: "auth/organizationId",
      token: "auth/token",
    }),

    formName() {
      return this.formDetails?.form_title?.replaceAll("_", " ");
    },

    formPayload() {
      return {
        field_names: this.field_names,
        form_fields: this.formData,
        is_payment: this.formDetails?.is_payment,
        is_private: this.formDetails?.is_private,
      };
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    getSchema(val) {
      this.formData = val.detail[0];
    },

    getBaseUrl() {
      return process.env.VUE_APP_BASEURL;
    },

    async fetchFormsById() {
      this.isFetching = true;
      try {
        let response = await this.$store.dispatch(
          "formBuilder/getSingleForm",
          this.$route.params.id
        );

        this.fullForm = response.data;

        this.currentFormSchema = this.fullForm.data.form_fields;

        this.formDetails = {
          form_title: this.fullForm.data.form_title,
          form_description: this.fullForm.data.form_description,
          is_private: this.fullForm.data.is_private,
          form_type: this.fullForm.data.form_type,
          is_payment: this.fullForm.data.is_payment,
          has_signature: this.fullForm.data.has_signature,
          bill: this.fullForm.data.bill,
          qrcode: this.fullForm.data.qrcode,
          has_workflow: this.fullForm.data.has_workflow,
          hypn_id: this.fullForm.data.hypn_id,
          endpoint: this.fullForm.data.endpoint,
        };

        this.form_hypn_id = this.fullForm.data.hypn_id;
        this.form_id = this.fullForm.id;
      } finally {
        this.isFetching = false;
      }
    },

    openFormSettings() {
      this.$refs.formsettings.openFormDialog();
    },

    async submitForm() {
      try {
        this.isSubmitting = true;

        await this.$store
          .dispatch("formBuilder/updateForm", {
            id: this.form_id,
            payload: this.formPayload,
          })
          .then((res) => {
            console.log(JSON.stringify(res.data, null, 2));
            this.$refs.feedBackModal.openModal({
              ...res.data,
              id: this.form_id,
            });

            this.showToast({
              sclass: "success",
              show: true,
              message: "Form " + this.formName + " Updated",
              timeout: 3000,
            });
          });
      } catch (error) {
        if (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: "Form " + this.formName + " could not be Updated",
            timeout: 3000,
          });
        }
      } finally {
        this.isSubmitting = false;
      }
    },

    setPreviewState(val) {
      this.canPreview = val.detail[0];
    },

    addToFieldNames() {
      if (this.formDetails?.form_type === "api") {
        this.formDetails?.bill.forEach((item) => {
          if (this.field_names.find((item) => item.key === "bill")) return;

          this.field_names.push({
            label: item.name,
            key: item.name.replaceAll(" ", "_"),
            type: "money",
            for: "bill",
            value: item.amount,
          });
        });
      }
    },

    addSubmittedBy() {
      if (this.field_names.find((item) => item.key === "submitted_by")) return;

      this.field_names.push({
        key: "submitted_by",
        type: "input",
        label: "Submitted By",
      });
    },

    checkFiles(val) {
      this.addUploadInput = val;
    },
  },
  watch: {
    formData: {
      handler(val) {
        if (val) {
          const data = [];
          val.map((item) => {
            if (item.type === "group" && item.properties.length > 0) {
              item.properties.map((i) => {
                data.push(i);
              });
            }
          });

          this.field_names = data.map((item) => {
            return {
              id: item.id,
              type: item.type === "input" ? item.attributes.type : item.type,
              label: item.label,
              value: item.value,
              required: item.required,
              ...(item.options && { options: item.options }), // if item.options is available, add it to the object
              key: item.label
                .toLowerCase()
                .replace(/ /g, "_")
                .replace(/[^\w-]+/g, ""),
            };
          });
        }

        this.addSubmittedBy();
        this.addToFieldNames();
      },
      deep: true,
      immediate: true,
    },
    formPayload: {
      handler(val) {
        if (process.env.NODE_ENV !== "production") {
          console.log(JSON.stringify(val, null, 2));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-form {
  padding: 0px 40px 20px 20px;
  margin-top: 48px;

  // mobile view
  @media (max-width: 600px) {
    padding: 0px 10px;
    margin-top: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titlex {
      font-size: 21px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      line-height: 39px;
    }

    &--actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__canvas-area {
    max-width: 600px;
    width: 100%;
    margin: 100px auto;

    .form-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;

      button {
        padding: 2px 10px !important;
        margin: 0 !important;
        color: var(--body-text, rgba(25, 40, 61, 0.8));
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        text-decoration-line: underline;

        transform: translateY(10px);
      }
    }

    .form-canvas {
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
